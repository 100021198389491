import HomePage from "./components/HomePage";
import DetailsPage from "./components/DetailsPage";
import { SectionsContainer, Section } from 'react-fullpage';

function App() {
  let options = {
    sectionClassName:     'section',
    anchors:              ['Home', 'Information'],
    scrollBar:            false,
    navigation:           false,
    verticalAlign:        false,
    arrowNavigation:      true  
  };
  return (
    <SectionsContainer className="container" {...options}>
      <Section>
        <HomePage />
      </Section>
      <Section>
        <DetailsPage />
      </Section>
    </SectionsContainer>
  );
}

export default App;
