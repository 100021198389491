import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Intro = styled(motion.h3)`
  width: 70vw;
  padding: 0 5em;
  font-weight: 500;
  text-align: center;
  font-size: clamp(1em, 1.5vw, 1.5em);
`;

const charVariants = {
    hidden: { opacity: 0},
    reveal: { opacity: 1},
}

const SubInfo = () => {
    const text = "Software Engineer currently based in Makati, Philippines".split("");
    return (
        <>
            <Intro
                initial="hidden"
                animate="reveal"
                transition={{staggerChildren: .05}}
            >
                {text.map((char, index) => (
                    <motion.span
                        key={index}
                        variants={charVariants}
                    >
                        {char}
                    </motion.span>
                ))}
                {/* <motion.span>|</motion.span> */}
            </Intro>
            
        </>
        
    )
}

export default SubInfo;