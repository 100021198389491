import React from 'react';
import styled from 'styled-components';
import SpringSpan from '../utils/SpringSpan';
import { motion } from 'framer-motion';

const ImportantText = styled.h3`
  transform: scaleY(1.4);
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0 1.2rem;
  text-align: center;
  font-size: clamp(4rem, 10vw, 9rem);
  text-shadow: 2px 2px 3px #678983;
`;

const Container = styled(motion.div)`
    @media only screen and (min-width: 740px){
        display: flex;
    }
    justify-content: center;
    align-items: center;
`;

const Name = () => {
    const names = ["Syuujie", "Yoshino"];

    //todo: change transition of name
    // const animation = {
    //     scale: [.2, .3, .4, .5, .6, .7, .8, .9, 1],
    //     y: [0, -20, 0, -40, 0, -60, 0, -100, 0],
    //     opacity: [.1, .2, .3, .4, .5, .6, .7, .8, 1]
    // };
    // const transitions = {
    //     duration: 3,
    //     times: [0, .1, .2, .3, .4, .5, .6, .8, 1],
    //     repeat: Infinity,
    //     repeatDelay: 1
    // }

    const nameVariants = {
        initial: { y: 200, opacity: 0 },
        final: { y: 0, opacity: 1 }
      }
    
    return (
        <Container 
            initial="initial"
            animate="final"
            variants={nameVariants}
            transition={{type:"tween"}}
        // animate={ animation }
        // transition={ transitions }
        >
            {names.map((n, i) => (
                <ImportantText key={i}>
                    {n.split("").map((char, index) => (
                        <SpringSpan key={index}>
                            {char === " " ? "\u00A0" : char}
                        </SpringSpan>
                    ))}
                </ImportantText>
            ))}
        </Container>
    )
}

export default Name;