import styled from 'styled-components';
import Header from './common/Header';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #678983;
    position: relative;
`;

const DetailsPage = () => {
    return (
        <>
            <Header />
            <Container>
            
            </Container>
        </>
        
    )
}

export default DetailsPage;