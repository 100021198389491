import React from 'react';
import styled, { keyframes } from 'styled-components';;

const WheelScroll = keyframes`
  to {
    opacity: 0;
    top: 45px;
  }
`;

const IconScroll = styled.div`
  width: 3rem;
  height: 5rem;
  border: 2px solid #E6DDC4;
  border-radius: 100px;
  position: absolute;
  bottom: 3%;
  &::before {
    content: '';
    border: solid #E6DDC4;
    border-width: 0 .3rem .3rem 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    opacity: 1;
    animation: ${WheelScroll} 3s infinite;
  }
`;

const ScrollDown = () => {
    return (
        <IconScroll />
    )
}

export default ScrollDown;