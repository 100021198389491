import { useState } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import styled from 'styled-components';

const Letters = styled(motion.span)`
    display: inline-block;
    padding: 0rem .1rem;
`

const SpringSpan = ({children}) => {
    const controls = useAnimationControls();
    const [isPlaying, setIsPlaying] = useState(false);

    const spring = () => {
        controls.start({
            transform: [
                "scale(1, 1)",
                "scale(1.4, .55)",
                "scale(.65, 1.35)",
                "scale(1.35, .75)",
                "scale(.8, 1.15)",
                "scale(1.4, .55)",
                "scale(.75, 1.25)",
                "scale(1.25, .85)",
                "scale(.9, 1.05)",
                "scale(1, 1)",
            ],
            transition: {
                times: [0, .6, .65, .7, .75, .8, .85, .9, 1]
            }
        });
        setIsPlaying(true);
    }
    return (
        <Letters
            animate={controls}
            onMouseOver={() => {
                if (!isPlaying) spring()
            }}
            onAnimationComplete={() => setIsPlaying(false)}
        >
            {children}
        </Letters>
    )
}

export default SpringSpan;