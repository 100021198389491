import React from 'react';
import styled from 'styled-components';
import Name from './common/Name';
import SubInfo from './common/SubInfo';
import ScrollDown from './common/ScrollDown';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #181D31;
  position: relative;
`;

const IntroContainer = styled.div`
  letter-spacing: .3rem;
  word-spacing: 1rem;
  color: #F0E9D2;
  position: relative;
`;

const HomePage = () => {
    return (
      <Container>
        <IntroContainer>
          <Name />
          <SubInfo />
        </IntroContainer>
        <ScrollDown />
      </Container>
    )
}

export default HomePage;