import styled from 'styled-components';

const Container = styled.div`
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #181D31;
`;

const Header = () => {
    return (
        <Container>
        </Container>
    )
}

export default Header;